<template>
  <div>
    <ApiErrorMessage :message="apiErrorMessage" />
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
         <!-- Sorting not required not -->
          <!--<template class="pa-0">
            <v-select
              class="custom-select"
              height="40"
              :label="$t('messages.sortBy')"
              :items="['Received', 'Sent']"
              v-model="filterBy"
              dense
              solo
              elevation="0"
            ></v-select>
          </template>
          <v-spacer></v-spacer>-->
          <div>
            <h3 class="box-title green-text">
            {{ $t("messages.messageCenter") }}
          </h3>
          </div>
          <v-spacer></v-spacer>
          <div class="rd-member-search">
            <v-text-field
            hide-details="auto"
            class="custom-search"
            height="40"
            elevation="0"
            v-model="searchText"
            append-icon="mdi-magnify"
            dense
            solo
            @input="searchPostThreads"
            :placeholder="$t('messages.searchWords')"
          ></v-text-field>
          </div>
        </div>
      <div class="custom-card">
        <div class="custom-box">
          <!-- :header-props="{ sortIcon: null }" -->
          <v-data-table
            :disable-sort="true"
            :headers="headers"
            :items="postThreads.data"
            fixed-header
            class="disabled-footer cursorPointer color-light-green"
            :loading="loading"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            @click:row="handleAction"
            title="Click Here to view"
          >
            <!-- <template v-slot:item.unread_messages_count="{ item }">
              <v-chip color="red" dark v-if="item.unread_messages_count">
                {{ item.unread_messages_count }}
              </v-chip>
            </template> -->
            <template v-slot:item.latest_message.sent_at="{ item }">
              <div class="inline-r">
                <span class="datebreak date-time"> <v-icon aria-hidden="false">
                  mdi-clock-time-five-outline
                </v-icon>
                <span >{{ item.latest_message.sent_at | MMDDYYdatefilter }}</span>
              </span>
              <span v-if="item.unread_messages_count" class="count-label"> {{ item.unread_messages_count }}</span>
              </div>
            </template>
            <template v-slot:item.post.message="{ item }">
              <pre class="truncate">{{ item.post.message }}</pre>
            </template>
            <!-- <template v-slot:item.action="{ item }">
              <v-btn
                @click="
                  $router.push(`/dashboard/post-thread/${item.id}/messages`)
                "
                small
                color="green darkan-4 green-gradient white--text"
                class="text-capitalize"
                >{{ $t("messages.view") }}
              </v-btn>
            </template> -->
          </v-data-table>
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p> {{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  {{ $t("pagination.previous") }}
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  {{ $t("pagination.next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import SocketMixin from "@/mixins/SocketMixin";
import { mapActions, mapGetters } from "vuex";
import { DEBOUNCE_TIMEOUT, ITEMS_PER_PAGE } from "@/constants/common";

export default {
  name: "MessageCenter",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  mixins: [SocketMixin.listeners],
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",


      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      loading: false,
      searchText: "",
      //filterBy: null, //It's used from commented code
      apiErrorMessage: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },


    ...mapGetters({
      user: "user/getUser",
      postThreads: "postThread/getPostThreads",
    }),
    /* Sorting not required not */
    /*filteredItems() {
      return this.food.filter((i) => {
        return !this.filterBy || i.type === this.filterBy;
      });
    },*/
    headers() {
      return [
        // { text: "New", align: "left", value: "unread_messages_count" },
        { text: "Date", align: "left", value: "latest_message.sent_at" },
        { text: "From", align: "left", value: "owner.name" },
        { text: "To", align: "left", value: "recipient.name" },
        { text: "Message", align: "left", value: "post.message" },
        // { text: "Action", align: "left", value: "action" },
      ];
    },
  },

  async mounted() {
    await this.fetchPostThreads();
  },

  methods: {
    ...mapActions({
      getPostThreads: "postThread/getPostThreads",
    }),

    searchPostThreads: debounce(function () {
      this.fetchPostThreads();
    }, DEBOUNCE_TIMEOUT),


    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchPostThreads(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchPostThreads(); // Fetch new data based on the current page
      }
    },

    async fetchPostThreads() {
      this.loading = true;
      try {
        if (this.searchText) {
          this.page = 1;
        }
        await this.getPostThreads({
          page: this.currentPage,
          items_per_page: this.perPage,
          search: this.searchText,
        });
        if (this.postThreads.meta) {
          this.totalEntries = this.postThreads.meta.total;
          this.itemsPerPage = parseInt(this.postThreads.meta.per_page);
          this.pageCount = this.postThreads.meta.last_page;
        } else {
          this.itemsPerPage = this.postThreads.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    handleAction(item) {
      console.log(item);
      this.$router.push(`/dashboard/post-thread/${item.id}/messages`);
    }
  },
};
</script>
